<template style="text-align:center;">
  <div class="cart_map_box" style="width:830px;margin:0 auto;border:none;">
    <div id="iframe"></div>
    <!-- <iframe id="iframe1" :src="iframeUrl" frameborder="1" style="display:block;width:100%;height:643px;"></iframe> -->
  </div><!-- cart_map_box // -->
</template>

<script>

export default {
  name: 'CargoMapDetail',
  components: {
  },
  props: {
    detailParams: {
      type: Object,
      default: null
    },
    lang: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      iframeUrl: '',
      pWidth: 830, // the width of the embedded map in pixels or percentage
      pHeight: 650, // the height of the embedded map in pixels or percentage
      border: '0', // the width of the border around the map (zero means no border)
      showScroll: 'no', // to display info (auto or yes or no)
      crrCd: 'KMD', // carrier Cd
      apiKey: '4dRjdNLMaEqUgTNG66AmJQOJVMF1njMNQKAIjO2J' // request api key
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.init()
    })
  },
  created () {
    // this.init()

    window.p_width = 830
    window.p_height = 650
    window.crr_cd = 'KMD'
    window.srch_val = ''
    window.api_key = '4dRjdNLMaEqUgTNG66AmJQOJVMF1njMNQKAIjO2J'
  },
  methods: {
    async init () {
      const TH = this
      const blNo = TH.detailParams.blNo
      window.srch_val = TH.detailParams.blNo
      //const bkgNo = TH.detailParams.bkgNo
      console.log('MAP TH.detailParams>>>>>>', TH.detailParams)
      if (this.$ekmtcCommon.isNotEmpty(blNo)) {
        if ($('#iframe').length) {
          window.createMap()
        }
          // const url = 'https://cargoeye.valuelinku.com/cargoEye/map_common_v3.do?crr_cd=' + TH.crrCd + '&srch_val=' + blNo + '&api_key=' + TH.apiKey + '&showScroll=' + TH.showScroll + '&showtitle=' + true + '&showinfo=' + true + '&p_width=' + TH.pWidth + '&p_height=' + TH.pHeight

          // TH.iframeUrl = url
      }
    }
  }
}
</script>

 <style scoped>
 </style>
